<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row class="ml-3">
          <v-col v-if="showStoreSelect" class="d-flex" cols="6" sm="6">
            <v-select
              v-model="storeId"
              :disabled="isStore"
              :items="stores"
              label="Select Store"
              style="margin-top: 2em"
              item-value="id"
              item-text="name"
              @change="getSalesByStore"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-card-title>
          <div class="flex justify-between flex-wrap">
            <v-btn
              v-if="addSales"
              class="mb-2 mt-10 button"
              dark
              color="info"
              @click="$router.push('/app/sales')"
            >
              <v-icon>mdi-plus</v-icon>
              New Sale
            </v-btn>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-select
              v-model="searchType"
              label="Choose Search Style"
              :items="searchStyles"
              item-value="code"
              item-text="name"
              dense
              outlined
              style="margin-top: 2em"
            ></v-select>
            <div></div>
          </div>
        </v-card-title>
        <paginated-data-table
          :headers="headers"
          :items="items.sales"
          :loading="loading"
          :totalPages="items.lastPage"
          :searchType="searchType"
          itemKey="invoiceId"
          title="Sales"
          url="/app/sales/view/"
          @next="loadSales"
          @click="handleClick"
          @searchByDate="searchByDate"
          @searchWithCode="searchWithCode"
        ></paginated-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  GET_SALES_LIST,
  GET_SALES_WITH_INVOICE,
  COMPANY_STORE,
  GET_STORE_SALES_LIST,
} from "../../../constants/graphql";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product",
  },

  data() {
    return {
      isEditing: false,
      viewDialog: false,
      search: "",
      selected: [],
      storeId: "",
      stores: [],
      userRole: this.$store.state.authData.role || "",
      userPermission: this.$store.state.authData.userPermissions,
      headers: [
        {
          text: "S/N",
          value: "sno",
          width: "10%",
        },
        {
          text: "Invoice Id",
          sortable: false,
          value: "invoiceId",
        },
        { text: "Price", value: "totalPrice" },
        { text: "Tax", value: "tax" },
        { text: "Discount", value: "discount" },
        { text: "Payment Type", value: "paymentType" },
        { text: "Payment Status", value: "paymentStatus" },
        { text: "Date Created", value: "createdDate" },
      ],
      items: {
        sales: [],
        lastPage: 0,
      },
      defaultItems: {
        sales: [],
        lastPage: 0,
      },
      invoiceItem: {
        sales: [],
        lastPage: 0,
      },
      invoiceCode: "",
      loading: false,
      saleId: null,
      startDate: null,
      endDate: null,
      invoiceCode: null,
      searchType: "withDate",
      valid: true,
      totalItems: 0,
      currentPage: 1,
      searchStyles: [
        {
          name: "Search with Date",
          code: "withDate",
        },

        {
          name: "Search with Invoice Code",
          code: "withCode",
        },
      ],
    };
  },
  computed: {
    addSales() {
      if (this.userPermission.includes("add_sales")) {
        return true;
      }
    },
    showStoreSelect() {
      if (this.userRole.toLowerCase() == "administrator") {
        return true;
      } else {
        return false;
      }
    },
    isStore() {
      return false;
    },
  },
  apollo: {
    stores: {
      query: COMPANY_STORE,
      update: (result) => result.getCompanyStoreList,
      error(err) {
        handleError(err);
      },
    },
    items: {
      query: GET_SALES_LIST,
      variables() {
        return {
          currentPage: this.currentPage,
          perPage: 30,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      update(result) {
        this.loading = false;
        return result.getSalesList;
      },
    },
  },

  mounted() {
    this.refresh();
  },

  methods: {
    default() {
      this.items = this.defaultItems;
      this.invoiceItem = this.defaultItems;
      this.loading = true;
    },
    getSalesByStore() {
      this.loading = true;
      this.$apollo
        .query({
          query: GET_STORE_SALES_LIST,
          variables: {
            currentPage: this.currentPage,
            perPage: 30,
            storeId: this.storeId,
          },
        })
        .then((result) => {
          let data = result.data.getStoreSalesList;
          this.items = data;
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.isEditing = false;
    },
    refresh() {
      this.default();
      this.$apollo.queries.items.refetch();
    },
    loadSales(pageNumber) {
      this.currentPage = pageNumber;
      this.default();
      this.$apollo.queries.items.refetch();
    },
    handleClick(item) {
      this.$router.push(`/app/sales/view/${item.id}`);
    },
    searchByDate(start_date, end_date) {
      this.default();
      this.startDate = start_date;
      this.endDate = end_date;
      this.$apollo.queries.items.refetch();
    },
    searchWithCode(value) {
      this.default();
      this.invoiceCode = value;
      this.$apollo
        .query({
          query: GET_SALES_WITH_INVOICE,
          variables: {
            invoiceCode: this.invoiceCode,
          },
        })
        .then((result) => {
          let data = result.data.getSalesWithInvoiceCode
            ? [result.data.getSalesWithInvoiceCode]
            : [];
          this.items = {
            sales: data,
            lastPage: data.length,
          };
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
      td {
        padding: 0.95rem !important;
      }
    }
  }

  .format-pre pre {
    background: #49483e;
    color: #f7f7f7;
    padding: 10px;
    font-size: 14px;
  }
}
</style>
